<script>
  import Group from "$lib/admin/components/Group.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';

  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <Group addClass="form-item">
    {#if props}
      <SunEditorForm
        bind:text={props.content}
        placeholder="Write some great content"
        on:keyup />
    {/if}
  </Group>
{:else}
  <Block
    blockClass="block-content"
    gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
    {props}>
    {#if props && props.content}
      {@html props.content}
    {:else}
      <p class="empty-content">No content written yet. Write some text to get started!</p>
    {/if}
  </Block>
{/if}
<script>
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import TableOfContent from "$lib/admin/components/blocks/helpers/TableOfContent.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import Button from "$lib/admin/components/blocks/helpers/Button.svelte";
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";

  // props.header: Props passed to separate header component
  // props.content: Content written below header
  // props.showCTA: bool - show/hide a CTA button (only works on review pages)
  // props.hideTOC: bool - show/hide a TOC for the page in the intro section

  export let props = null;
  export let adminView = false;
  export let operator = null;
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      bind:value={props.header}
      label="Heading"
      placeholder="Write an H1"
      on:keyup
    />
  </Group>
  <Group addClass="form-item">
    <SunEditorForm
      placeholder="Introduction"
      bind:text={props.content}
      on:keyup
    />
  </Group>
{:else}
  <Block blockClass="block-intro" {props}>
    <h1>{props.header ? props.header : ""}</h1>
    {@html props.content ? props.content : ""}

    {#if props.showCTA}
      {#if props.introCTAType === "internal"}
        <div class="cta-section">
          <Button {props} />
        </div>
      {:else}
        <div class="cta-section">
          <Button
            {props}
            type="cta"
            dataElement="hero"
            dataOperator={operator?.name_lower}
          />

          <i><Terms {operator} /></i>
        </div>
      {/if}
    {/if}

    {#if !props.hideTOC}
      <TableOfContent type="scrollable" />
    {/if}
  </Block>
{/if}
